import ContactMessageStatusChange, {
    SerializedContactMessageStatusChange,
} from "@/models/contact-message-status-change";

export const CONTACT_MESSAGE_STATUSES: Array<string> = [
    "new",
    "handled",
    "invalid",
    "processing",
];

export interface SerializedContactMessage {
    user_id: string;
    id: string;
    phone_number: string;
    message: string;
    status: string;
    status_changes: Array<SerializedContactMessageStatusChange>;
    created_at: string;
    can_contact_on_whatsapp: boolean;
}

export default class ContactMessage {
    private readonly _userId: string;
    private readonly _id: string;
    private readonly _phoneNumber: string;
    private readonly _message: string;
    private _status: string;
    private readonly _createdAt: Date;
    private readonly _statusChanges: Array<ContactMessageStatusChange>;
    private readonly _canContactOnWhatsapp: boolean;

    constructor(message: SerializedContactMessage) {
        this._userId = message.user_id;
        this._id = message.id;
        this._phoneNumber = message.phone_number;
        this._message = message.message;
        this._status = message.status;
        this._createdAt = new Date(message.created_at);
        this._canContactOnWhatsapp = message.can_contact_on_whatsapp;
        this._statusChanges = message.status_changes.map(
            (status: SerializedContactMessageStatusChange) => {
                return new ContactMessageStatusChange(status);
            }
        );
    }

    get canContactOnWhatsapp(): boolean {
        return this._canContactOnWhatsapp;
    }

    get statusChanges(): Array<ContactMessageStatusChange> {
        return this._statusChanges;
    }

    get userId(): string {
        return this._userId;
    }

    get id(): string {
        return this._id;
    }

    get timestamp(): number {
        return this.createdAt.getTime();
    }

    get phoneNumber(): string {
        return this._phoneNumber;
    }

    get message(): string {
        return this._message;
    }

    set status(value: string) {
        this._status = value;
    }

    get status(): string {
        if (!this._status) {
            return "new";
        }
        return this._status;
    }

    get statusColor(): string {
        switch (this.status) {
            case "new":
                return "purple lighten-2";
            case "handled":
                return "success";
            case "invalid":
                return "orange lighten-1";
            default:
                return "default";
        }
    }

    get createdAt(): Date {
        return this._createdAt;
    }
}
