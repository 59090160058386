import { userNameFromId } from "@/models/user";

export interface SerializedContactMessageStatusChange {
    previous_status: string;
    current_status: string;
    changed_at: string;
    changed_by_user_id: string;
    changed_by_user_name: string;
}

export default class ContactMessageStatusChange {
    private readonly _previousStatus: string;
    private readonly _currentStatus: string;
    private readonly _changedAt: Date;
    private readonly _changedByUserId: string;
    private readonly _changedByUserName: string;

    constructor(statusChange: SerializedContactMessageStatusChange) {
        this._previousStatus = statusChange.previous_status;
        this._currentStatus = statusChange.current_status;
        this._changedAt = new Date(statusChange.changed_at);
        this._changedByUserId = statusChange.changed_by_user_id;
        this._changedByUserName = statusChange.changed_by_user_name;
    }

    get previousStatus(): string {
        return this._previousStatus;
    }

    get currentStatus(): string {
        return this._currentStatus;
    }

    get changedAt(): Date {
        return this._changedAt;
    }

    get customerName(): string {
        if (this.changedByUserName) {
            return this.changedByUserName;
        }
        return userNameFromId(this.changedByUserId);
    }
    get changedByUserId(): string {
        return this._changedByUserId;
    }

    get statusColor(): string {
        switch (this.currentStatus) {
            case "new":
                return "purple lighten-2";
            case "handled":
                return "success";
            case "invalid":
                return "orange lighten-1";
            default:
                return "default";
        }
    }

    get changedByUserName(): string {
        return this._changedByUserName;
    }
}
